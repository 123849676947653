import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AjaxService } from 'src/app/service/ajax.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-cadastro-usuario',
  templateUrl: './cadastro-usuario.component.html',
  styleUrls: ['./cadastro-usuario.component.scss']
})
export class CadastroUsuarioComponent implements OnInit {

  nome: string;
  email: string;
  senha: string;
  confirmacaoSenha: string;
  maskTeleArea = ['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor(
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService
    
    ) { }

  ngOnInit() {
    this.nome = "";
    this.email = "";
    this.senha = "";
    this.confirmacaoSenha = "";
  }

  validar(): boolean{

    if (this.nome == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Nome");
      return false;
    }

    if (this.email == "" || this.email == "") {
      this.util.toasterShowError("Atenção", "Preencha o Login");
      return false;
    }

    if (this.senha == "" || this.confirmacaoSenha == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Senha e Confirmação de Senha");
      return false;
    }

    if (this.senha != this.confirmacaoSenha) {
      this.util.toasterShowError("Atenção", "Os campos Senha e Confirmação não sao iguais");
      return false;
    }

    return true;
  }

  salvar(){
    let dados = {
      "nome": this.nome,
      "email": this.email,
      "login": this.email,
      "senha": this.senha,

    }
    let url = API + "Usuario/Usuario" ;
    this.util.showLoading();
    this.ajax.post(url, dados)
      .subscribe((resp) => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowSuccess("Sucesso", "Cadastrado com sucesso!");
        this.router.navigate(['/login']);

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.messageError(err);
        //this.util.toasterShowError("Atenção", "Erro ao tentar realizar o cadastro");
      });
  }

  cadastrar(){

    if(this.validar()){
      this.salvar();
    }

  }

}
