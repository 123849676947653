<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>
<div class="row">
  <div class="col-xl-12 col-md-12 col-sm-12">
    <app-card cardTitle="Edição" [options]="false">

      <div class="row">
        <div class="col-md-12" *ngIf="tipoQrcode == 'Link'">
          <div align="center">
            <div class="form-group col-md-6 ">
              <label>Titulo QRCode</label>
              <input type="text" class="form-control" [(ngModel)]="tituloQrcode" name="tituloQrcode" placeholder="">
            </div>
            <div class="form-group col-md-6 ">
              <label>URL</label>
              <input type="text" class="form-control" [(ngModel)]="urlQrcode" name="urlQrcode" placeholder="https://">
            </div>
          </div>

        </div>

        <div class="col-md-12" *ngIf="tipoQrcode == 'Mapa'">
          <div align="center">
            <div class="form-group col-md-6 ">
              <label>Titulo QRCode</label>
              <input type="text" class="form-control" [(ngModel)]="tituloQrcode" name="tituloQrcode" placeholder="">
            </div>
            <div class="row">
              <div class="form-group col-md-8 col-xl-8 col-sm-12">
                <label>Rua</label>
                <input type="text" class="form-control" [(ngModel)]="rua" name="rua">
              </div>
              <div class="form-group col-md-4 col-xl-4 col-sm-12">
                <label>Número</label>
                <input type="text" class="form-control" [(ngModel)]="numero" name="numero">
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-4 col-xl-4 col-sm-12">
                <label>Bairro </label>
                <input type="text" class="form-control" [(ngModel)]="bairro" name="bairro">
              </div>
              <div class="form-group col-md-4 col-xl-4 col-sm-12">
                <label>Cidade</label>
                <input type="text" class="form-control" [(ngModel)]="cidade" name="cidade">
              </div>
              <div class="form-group col-md-4 col-xl-4 col-sm-12">
                <label>Estado</label>
                <input type="text" class="form-control" [(ngModel)]="estado" name="estado">
              </div>
            </div>
          </div>

        </div>

        <div class="col-md-12" *ngIf="tipoQrcode == 'Texto'">
          <div class="form-group ">
            <label for="exampleInputEmail1">Titulo QRCode</label>
            <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
              [(ngModel)]="tituloQrcode" name="tituloQrcode">
          </div>
          <div class="form-group ">
            <label for="exampleInputEmail1">Texto</label>
            <!--<textarea class="form-control"  rows="3" [(ngModel)]="mensagemQrcode" name="mensagemQrcode"></textarea>-->
            <app-tinymce [(ngModel)]='mensagemQrcode' #tinymce='tinymce' name="mensagemQrcode"></app-tinymce>
          </div>
        </div>

        <div class="col-md-12" *ngIf="tipoQrcode == 'Pdf' || tipoQrcode == 'Arquivo' || tipoQrcode == 'Mp3'">
          <div align="center">
            <div class="form-group col-md-6">
              <label for="exampleInputEmail1">Titulo QRCode</label>
              <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                [(ngModel)]="tituloQrcode" name="tituloQrcode">
            </div>
            <div class="form-group col-md-6">
              <label class=" control-label">Selecione se é exibição ou Download</label>
              <select class="form-control" [(ngModel)]='exibicaoDownload' disabled>
                <option value="78DE2DA5-590F-4459-9140-D4E433CF089B">Arquivo Modo Exibição </option>
                <option value="78DE2DA5-590F-4459-9140-D4E433CF089C">Arquivo Modo Download </option>
              </select>
            </div>
            <div class="form-group col-6" *ngIf="tipoQrcode == 'Pdf'">
              <label for="exampleInputEmail1">Selecione o PDF</label>
              <file-upload  [(ngModel)]="uploadedFilePdF" [fileslimit]="1" simple accept="application/pdf"></file-upload>
            </div>
            <div class="form-group col-6" *ngIf="tipoQrcode == 'Arquivo'">
              <label for="exampleInputEmail1">Selecione o Arquivo</label>
              <file-upload  [(ngModel)]="uploadedFilePdF" [fileslimit]="1" simple></file-upload>
            </div>
            <div class="form-group col-6" *ngIf="tipoQrcode == 'Mp3'">
              <label for="exampleInputEmail1">Selecione o MP3</label>
              <file-upload  [(ngModel)]="uploadedFilePdF" [fileslimit]="1" simple accept="audio/*"></file-upload>
            </div>
          </div>
        </div>

        <div class="col-md-12" *ngIf="tipoQrcode == 'Conteudo'">
          <div class="form-group">
            <label for="exampleInputEmail1">Titulo QRCode</label>
            <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
              [(ngModel)]="tituloQrcode" name="tituloQrcode">
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Conteúdo</label>
            <app-tinymce [(ngModel)]='mensagemQrcode' #tinymce='tinymce' name="mensagemQrcode"></app-tinymce>
          </div>
        </div>

        <div class="col-md-6" *ngIf="tipoQrcode == 'Email'">
          <div class="form-group col-md-6">
            <label for="exampleInputEmail1">Titulo QRCode</label>
            <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
              [(ngModel)]="tituloQrcode" name="tituloQrcode">
          </div>
          <div class="form-group col-md-6">
            <label for="exampleInputEmail1">Email</label>
            <input type="text" class="form-control" aria-describedby="emailHelp" placeholder="">
          </div>
          <div class="form-group col-md-6">
            <label for="exampleInputEmail1">Assunto</label>
            <input type="text" class="form-control" aria-describedby="emailHelp" placeholder="">
          </div>
          <div class="form-group col-md-6">
            <label for="exampleInputEmail1">Mensagem</label>
            <textarea class="form-control" rows="3"></textarea>
          </div>
        </div>

        <div class="col-md-12" *ngIf="tipoQrcode == 'Telefone'">
          <div align="center">
            <div class="form-group col-md-6">
              <label for="exampleInputEmail1">Titulo QRCode</label>
              <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                [(ngModel)]="tituloQrcode" name="tituloQrcode">
            </div>
            <div class="form-group col-md-6">
              <label for="exampleInputEmail1">Número de Telefone</label>
              <input type="text" class="form-control" [textMask]="{mask: maskTeleArea}" aria-describedby="emailHelp"
                placeholder=" (99) 9 9999-9999" [(ngModel)]="urlQrcode" name="urlQrcode">
            </div>
          </div>
        </div>

        <div class="col-md-12" *ngIf="tipoQrcode == 'Whatsapp'">
          <div align="center">
            <div class="form-group col-md-6">
              <label for="exampleInputEmail1">Titulo QRCode</label>
              <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                [(ngModel)]="tituloQrcode" name="tituloQrcode">
            </div>
            <div class="form-group col-md-6">
              <label for="exampleInputEmail1">Número de Telefone</label>
              <input type="text" class="form-control" [textMask]="{mask: maskTeleArea}" placeholder="(99) 9 9999-9999"
                [(ngModel)]="urlQrcode" name="urlQrcode">
            </div>
            <div class="form-group col-md-6">
              <label for="exampleInputEmail1">Mensagem</label>
              <textarea class="form-control" [(ngModel)]="mensagemQrcode" name="mensagemQrcode" rows="3"></textarea>
            </div>
          </div>
        </div>

        <div class="col-md-12" *ngIf="tipoQrcode == 'Telegram'">
          <div align="center">
            <div class="form-group col-md-6">
              <label for="exampleInputEmail1">Titulo QRCode</label>
              <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                [(ngModel)]="tituloQrcode" name="tituloQrcode">
            </div>
            <div class="form-group col-md-6">
              <label for="exampleInputEmail1">Username</label>
              <div class="input-group">

                <input type="text" class="form-control" placeholder="" [(ngModel)]="urlQrcode" name="urlQrcode">
              </div>
            </div>
          </div>
        </div>


        <div class="col-md-12" *ngIf="tipoQrcode == 'Skype'">
          <div align="center">
            <div class="form-group col-md-6">
              <label for="exampleInputEmail1">Titulo QRCode</label>
              <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                [(ngModel)]="tituloQrcode" name="tituloQrcode">
            </div>
            <div class="form-group col-md-6">
              <label for="exampleInputEmail1">Numero do Telefone ou Usuário do Skype</label>
              <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                [(ngModel)]="urlQrcode" name="urlQrcode">
            </div>
          </div>
        </div>

        <div class="col-md-12" *ngIf="tipoQrcode == 'Facebook'">
          <div align="center">
            <div class="form-group col-md-6">
              <label for="exampleInputEmail1">Titulo QRCode</label>
              <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                [(ngModel)]="tituloQrcode" name="tituloQrcode">
            </div>
            <div class="form-group col-md-6">
              <label for="exampleInputEmail1"> Usuário do Facebook</label>
              <div class="input-group">

                <input type="text" class="form-control" placeholder="" [(ngModel)]="urlQrcode" name="urlQrcode">
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12" *ngIf="tipoQrcode == 'Twitter'">
          <div align="center">
            <div class="form-group col-md-6">
              <label for="exampleInputEmail1">Titulo QRCode</label>
              <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                [(ngModel)]="tituloQrcode" name="tituloQrcode">
            </div>
            <div class="form-group col-md-6">
              <label for="exampleInputEmail1"> Usuário do Twitter</label>
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" [(ngModel)]="urlQrcode" name="urlQrcode">
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12" *ngIf="tipoQrcode == 'Youtube'">
          <div align="center">
            <div class="form-group col-md-6">
              <label for="exampleInputEmail1">Titulo QRCode</label>
              <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                [(ngModel)]="tituloQrcode" name="tituloQrcode">
            </div>
            <div class="form-group col-md-6">
              <label for="exampleInputEmail1"> Usuário do Youtube</label>
              <div class="input-group">

                <input type="text" class="form-control" placeholder="" [(ngModel)]="urlQrcode" name="urlQrcode">
              </div>
            </div>
          </div>

        </div>


        <div class="col-md-12" *ngIf="tipoQrcode == 'Linkedin'">
          <div align="center">
            <div class="form-group col-md-6">
              <label for="exampleInputEmail1">Titulo QRCode</label>
              <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                [(ngModel)]="tituloQrcode" name="tituloQrcode">
            </div>
            <div class="form-group col-md-6">
              <label for="exampleInputEmail1"> Usuário do Linkedin</label>
              <div class="input-group">
                <input type="text" class="form-control" placeholder="" [(ngModel)]="urlQrcode" name="urlQrcode">
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12" *ngIf="tipoQrcode == 'Google'">
          <div align="center">
            <div class="form-group col-md-6">
              <label for="exampleInputEmail1">Titulo QRCode</label>
              <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                [(ngModel)]="tituloQrcode" name="tituloQrcode">
            </div>
            <div class="form-group col-md-6">
              <label for="exampleInputEmail1"> Email do google</label>
              <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                [(ngModel)]="urlQrcode" name="urlQrcode">
            </div>
          </div>
        </div>

        <div class="col-md-12" *ngIf="tipoQrcode == 'Instagram'">
          <div align="center">
            <div class="form-group col-md-6">
              <label for="exampleInputEmail1">Titulo QRCode</label>
              <input type="text" class="form-control" aria-describedby="emailHelp" placeholder=""
                [(ngModel)]="tituloQrcode" name="tituloQrcode">
            </div>
            <div class="form-group col-md-6">
              <label for="exampleInputEmail1"> Usuário do instagram</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroupPrepend">@</span>
                </div>
                <input type="text" class="form-control" placeholder="" [(ngModel)]="urlQrcode" name="urlQrcode">
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="form-group  text-center">

        <button type="button" class="btn btn-primary" (click)="voltar()">
          <i class="feather icon-skip-back"></i>
          Voltar
        </button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button type="button" class="btn btn-success" (click)="salvar()">
          <i class="feather icon-check-circle"></i>
          Salvar
        </button>

      </div>

    </app-card>
  </div>
</div>
