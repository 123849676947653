<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>

<div class="row">
  <div class="col-xl-6 col-md-6 col-sm-12">
    <app-card cardTitle="{{titulo}}" [options]="false">

      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="form-group">
            <label for="exampleInputEmail1">Ativar Em </label>
            <input type="datetime-local" class="form-control" [(ngModel)]="dataAtivacao" name="dataAtivacao">
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="form-group">
            <label for="exampleInputEmail1">Expirar Em </label>
            <input type="datetime-local" class="form-control" [(ngModel)]="dataExpiracao" name="dataExpiracao">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="form-group">
            <label for="exampleInputEmail1">Senha </label>
            <input type="password" class="form-control" maxlength="12" [(ngModel)]="senha" name="senha">
            <small  class="form-text text-muted">Máximo 12 caracteres</small>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="form-group">
            <label for="exampleInputEmail1">Confirmação de Senha </label>
            <input type="password" class="form-control" maxlength="12" [(ngModel)]="confirmacaoSenha" name="confirmacaoSenha">
            <small  class="form-text text-muted">Máximo 12 caracteres</small>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="form-group">
            <label for="exampleInputEmail1">Quantidade Máxima de Leituras </label>
            <input type="number" class="form-control" [(ngModel)]="qtdMaximoLeitura" name="qtdMaximoLeitura">
          </div>
        </div>
      </div>
      <div class=" text-right">
        <div class="form-group">

            <button type="button" class="btn btn-warning"  (click)="voltar()">
                <i class="feather icon-check-circle"></i>
                Voltar
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

          <button type="button" class="btn btn-primary" *ngIf="this.configQrcode != null" (click)="salvarEdicao()">
            <i class="feather icon-check-circle"></i>
            Salvar Edição
          </button>

          <button type="button" class="btn btn-success" *ngIf="this.configQrcode == null" (click)="salvarNovo()">
            <i class="feather icon-check-circle"></i>
            Cadastrar
          </button>
        </div>

      </div>




      <!--<div class="col-md-12">
        <div align="center">
          <div class="form-group col-md-6">
            <label for="exampleInputEmail1">Quantidade Máxima de Leituras</label>
            <input type="text" class="form-control" 
              [(ngModel)]="tituloQrcode" name="tituloQrcode">
          </div>
          <div class="form-group col-md-6">
            <label for="exampleInputEmail1">Data de Ativação</label>
            <input type="text" class="form-control" [textMask]="{mask: maskTeleArea}" placeholder="(99) 9 9999-9999"
              [(ngModel)]="urlQrcode" name="urlQrcode">
          </div>
          <div class="form-group col-md-6">
            <label for="exampleInputEmail1">Data de Expiração</label>
            <input type="text" class="form-control" [textMask]="{mask: maskTeleArea}" placeholder="(99) 9 9999-9999"
              [(ngModel)]="urlQrcode" name="urlQrcode">
          </div>

          <div class="form-group col-md-6">
            <label for="exampleInputEmail1">Senha</label>
            <input type="text" class="form-control" [textMask]="{mask: maskTeleArea}" placeholder="(99) 9 9999-9999"
              [(ngModel)]="urlQrcode" name="urlQrcode">
          </div>

        </div>
      </div>
      <div class="form-group  text-center">

        <button type="button" class="btn btn-primary" *ngIf="this.configQrcode != null" (click)="salvar()">
          <i class="feather icon-check-circle"></i>
          Salvar Edição
        </button>

        <button type="button" class="btn btn-success" *ngIf="this.configQrcode == null" (click)="salvar()">
          <i class="feather icon-check-circle"></i>
          Cadastrar
        </button>

      </div>-->
    </app-card>


  </div>
</div>
