<div class="row">
  <div class="col-12">
    <app-card cardTitle="Periodo" [options]="false">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-3">
          <label class="sub-title">Data Inicial </label>
          <input type="date" class="form-control" [(ngModel)]="dataInicio" name="dataInicio" placeholder="">
        </div>
        <div class="col-md-3">
          <label class="sub-title">Data Final </label>
          <input type="date" class="form-control" [(ngModel)]="dataFim" name="dataFim" placeholder="">
        </div>
        <div class="col-sm-2 col-xl-2 text-left" style="margin-top: 28px;">
         
          <button type="button" class="btn btn-success " (click)="buscar()">Buscar &nbsp;<i
              class="feather icon-check-circle"></i>
          </button>
        </div>
      </div>

    </app-card>
  </div>
</div>

<div class="row">  
  <div class="col-xl-2 col-md-6">
    <app-card [hidHeader]="true" cardClass="bg-c-green" align="center">
      <h6 class="text-white"> <br>Total </h6>
      <h3 class="text-white">{{acertos.length + erros.length + errosOcoJaInserida.length + errosGerais.length}}</h3>
    </app-card>
  </div>
  <div class="col-xl-2 col-md-6">
    <app-card [hidHeader]="true" cardClass="bg-c-blue" align="center">
      <h6 class="text-white"> <br>Sucesso </h6>
      <h3 class="text-white">{{acertos.length }}</h3>
    </app-card>
  </div>
  <div class="col-xl-2 col-md-6">
    <app-card [hidHeader]="true" cardClass="bg-c-yellow" align="center">
      <h6 class="text-white"> <br>Oco. ja existe</h6>
      <h3 class="text-white">{{errosOcoJaInserida.length}}</h3>
    </app-card>
  </div>
  <div class="col-xl-2 col-md-6">
    <app-card [hidHeader]="true" cardClass="bg-c-red" align="center">
      <h6 class="text-white"> <br>Erros</h6>
      <h3 class="text-white">{{erros.length}}</h3>
    </app-card>
  </div>
  <div class="col-xl-2 col-md-6">
    <app-card [hidHeader]="true" cardClass="bg-c-red" align="center">
      <h6 class="text-white"> <br>Erro: Geral</h6>
      <h3 class="text-white">{{errosGerais.length}}</h3>
    </app-card>
  </div>
  <div class="col-xl-2 col-md-6">
    <app-card [hidHeader]="true" cardClass="bg-c-green" align="center">
      <h6 class="text-white"> <br>% Erros</h6>
      <h3 class="text-white">{{(((erros.length +errosGerais.length) *100) / (acertos.length + erros.length + errosOcoJaInserida.length + errosGerais.length)).toFixed(2)  }}</h3>
    </app-card>
  </div>
  
  

  <!--<div class="col-xl-2 col-md-6">
    <app-card [hidHeader]="true" cardClass="bg-c-yellow" align="center">
      <h6 class="text-white"> <br></h6>
      <h3 class="text-white"></h3>
    </app-card>
  </div>-->

</div>



<div class="row">

  <!--<div class="col-xl-12 col-md-12">
    <app-card cardTitle="Quantidade Erros Brudam Agrupados por dia" blockClass="scrollable-body" [options]="false">
      <perfect-scrollbar [style.max-height]="'310px'" [style.padding-top]="0">
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>

                <th>Data</th>
                <th>Quantidade</th>
         
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of errosAgrupados">
                <td>{{item.data | date:'dd/MM/yyyy hh:mm'}}</td>
                <td>{{item.qtd}}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </perfect-scrollbar>
    </app-card>
  </div>-->
  <div class="col-xl-6 col-md-6">
    <app-card cardTitle="Total: {{erros.length}} &nbsp;&nbsp;&nbsp;Erros Brudam " blockClass="scrollable-body" [options]="false">
      <perfect-scrollbar [style.max-height]="'810px'" [style.padding-top]="0">
        <div class="row ">
          <div class="col-md-6 col-sm-12 ">
            <label for="exampleInputEmail1">Filtro </label>
            <input type="text" class="form-control form-control-sm " [(ngModel)]="queryErro"
              placeholder="Digite seu filtro, sera feito uma busca em todas as colunas">
          </div>
        </div><br>
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th></th>
                <th>Data</th>
                <th>Telefone</th>
                <th>Nome</th>
                <th>Minuta</th>
                <th>Codigo</th>
                <th>Mensagem</th>

                <!--<th>Acão</th>-->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of erros  | fullTextSearch:queryErro">
               
                <td>
                  <button type="button" class="btn btn-icon btn-warning " (click)="modalAlterarStatus(item)">
                    <i class="fa fa-eye"></i>
                  </button>
                </td>
                <td>{{item.data | date:'dd/MM/yyyy hh:mm'}}</td>
                <td>{{item.telefone}}</td>
                <td>{{item.nome}}</td>
                <td>{{item.minuta}}</td>
                <td>{{item.codigO_BRUDAM}}</td>
                <td>{{item.mensageM_BRUDAM}}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </perfect-scrollbar>
    </app-card>
  </div>
  <div class="col-xl-6 col-md-6">
    <app-card cardTitle="Total: {{acertos.length}} &nbsp;&nbsp;&nbsp; Sucesso Brudam" blockClass="scrollable-body" [options]="false">
      <perfect-scrollbar [style.max-height]="'810px'" [style.padding-top]="0">
        <div class="row ">
          <div class="col-md-6 col-sm-12 ">
            <label for="exampleInputEmail1">Filtro</label>
            <input type="text" class="form-control form-control-sm " [(ngModel)]="queryAcerto"
              placeholder="Digite seu filtro, sera feito uma busca em todas as colunas">
          </div>
        </div><br>
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Data</th>
                <th>Telefone</th>
                <th>Nome</th>
                <th>Minuta</th>
                <th>Codigo</th>            
                <th></th>
                <!--<th>Acão</th>-->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of acertos  | fullTextSearch:queryAcerto">
                <td>{{item.data | date:'dd/MM/yyyy hh:mm'}}</td>
                <td>{{item.telefone}}</td>
                <td>{{item.nome}}</td>
                <td>{{item.minuta}}</td>
                <td>{{item.codigO_BRUDAM}}</td>       
              </tr>
            </tbody>
          </table>
        </div>

      </perfect-scrollbar>
    </app-card>
  </div>




  <div class="col-xl-6 col-md-6">
    <app-card cardTitle="Total: {{errosOcoJaInserida.length}} &nbsp;&nbsp;&nbsp;  Oco. ja existe" blockClass="scrollable-body" [options]="false">
      <perfect-scrollbar [style.max-height]="'810px'" [style.padding-top]="0">
        <div class="row ">
          <div class="col-md-6 col-sm-12 ">
            <label for="exampleInputEmail1">Filtro </label>
            <input type="text" class="form-control form-control-sm " [(ngModel)]="queryErroJaInserida"
              placeholder="Digite seu filtro, sera feito uma busca em todas as colunas">
          </div>
        </div><br>
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th></th>
                <th>Data</th>
                <th>Telefone</th>
                <th>Nome</th>
                <th>Minuta</th>
                <th>Codigo</th>
                <th>Mensagem</th>

                <!--<th>Acão</th>-->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of errosOcoJaInserida  | fullTextSearch:queryErro">
               
                <td>
                  <button type="button" class="btn btn-icon btn-warning " (click)="modalAlterarStatus(item)">
                    <i class="fa fa-eye"></i>
                  </button>
                </td>
                <td>{{item.data | date:'dd/MM/yyyy hh:mm'}}</td>
                <td>{{item.telefone}}</td>
                <td>{{item.nome}}</td>
                <td>{{item.minuta}}</td>
                <td>{{item.codigO_BRUDAM}}</td>
                <td>{{item.mensageM_BRUDAM}}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </perfect-scrollbar>
    </app-card>
  </div>


  <div class="col-xl-6 col-md-6">
    <app-card cardTitle="Total: {{errosGerais.length}} &nbsp;&nbsp;&nbsp;  Erro: Geral" blockClass="scrollable-body" [options]="false">
      <perfect-scrollbar [style.max-height]="'810px'" [style.padding-top]="0">
        <div class="row ">
          <div class="col-md-6 col-sm-12 ">
            <label for="exampleInputEmail1">Filtro </label>
            <input type="text" class="form-control form-control-sm " [(ngModel)]="queryErroGeral"
              placeholder="Digite seu filtro, sera feito uma busca em todas as colunas">
          </div>
        </div><br>
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th></th>
                <th>Data</th>
                <th>Telefone</th>
                <th>Nome</th>
                <th>Minuta</th>
                <th>Codigo</th>
                <th>Mensagem</th>

                <!--<th>Acão</th>-->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of errosGerais  | fullTextSearch:queryErro">
               
                <td>
                  <button type="button" class="btn btn-icon btn-warning " (click)="modalAlterarStatus(item)">
                    <i class="fa fa-eye"></i>
                  </button>
                </td>
                <td>{{item.data | date:'dd/MM/yyyy hh:mm'}}</td>
                <td>{{item.telefone}}</td>
                <td>{{item.nome}}</td>
                <td>{{item.minuta}}</td>
                <td>{{item.codigO_BRUDAM}}</td>
                <td>{{item.mensageM_BRUDAM}}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </perfect-scrollbar>
    </app-card>
  </div>
</div>