import {  Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CabecalhoTabela } from 'src/app/model/CabecalhoTabela';
import { AjaxService } from 'src/app/service/ajax.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-minha-tabela',
  templateUrl: './minha-tabela.component.html',
  styleUrls: ['./minha-tabela.component.scss']
})
export class MinhaTabelaComponent implements  OnDestroy ,OnInit {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  public arrayItens: [];
  @Input() endPoint: string;
  @Input() arrayCabecalho: CabecalhoTabela[];

  @Output() itemEditar = new EventEmitter();
  @Output() itemVisualizar = new EventEmitter();
  @Output() itemDeletar = new EventEmitter();
  @Output() itemSelecionar = new EventEmitter();
  

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  
  constructor(
    private ajax: AjaxService,
    public util: UtilService,

  ) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      language: {
        url: 'https://cdn.datatables.net/plug-ins/1.10.16/i18n/Portuguese-Brasil.json',
     },
    };
    this.buscar(true);
  }

  tableEditar(item: any){
    this.itemEditar.emit(item);
  }

  tableVisualizar(item: any){
    this.itemVisualizar.emit(item);
  }

  tableSelecionar(item: any){
    this.itemSelecionar.emit(item);
  }

  tableDeletar(item: any){
    this.itemDeletar.emit(item);
  }

  buscar(inicio: boolean){

    this.util.showLoading();
    let url = API + this.endPoint;
    return this.ajax.get(url)
      .subscribe(response => {  
        this.arrayItens = response.data;      
        this.util.closeLoading();
        if(inicio)
          this.dtTrigger.next();
        else
          this.rerender();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Dados");
      });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }


  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {

      dtInstance.destroy();
      this.dtTrigger.next();

    });
  }

}
