import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigQrcode } from 'src/app/model/configQrcode';
import { Qrcode } from 'src/app/model/Qrcode';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {

  idQrcode: any;
  configQrcode: ConfigQrcode;
  titulo: string;
  dataAtivacao: string;
  dataExpiracao: string;
  senha: string;
  confirmacaoSenha: string;
  qtdMaximoLeitura: number;


  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.idQrcode = this.activatedRoute.snapshot.params['qr'];
    this.buscaQrcode();
  }

  validar() {
    if (this.senha != this.confirmacaoSenha) {
      this.util.toasterShowError("Atenção", "Senhas não podem ser diferentes");
      return false;
    }

    if(this.senha != null && (this.senha.length > 12 || this.confirmacaoSenha.length > 12)){
      this.util.toasterShowError("Atenção", "Quantidade máxima de caracter é 12");
      return false;
    }

    return true;
  }

  pegarDados() {
    let dados = {
      "ativarEmData": this.dataAtivacao != '' ? this.dataAtivacao : null,
      "quantidadeMaximaLeitura": this.qtdMaximoLeitura,
      "senha": this.senha,
      "dataExpiracao": this.dataExpiracao != '' ? this.dataExpiracao : null,
      "id": undefined
    }
    return dados;
  }

  salvarNovo() {
    if (this.validar()) {
      debugger
      let dados = this.pegarDados();
      let url = API + "QrCode/" + this.idQrcode + "/Config";
      console.log(url);
      this.util.showLoading();
      this.ajax.post(url, dados)
        .subscribe((resp) => {
          debugger
          this.util.closeLoading();
          this.util.toasterShowSuccess("Sucesso", "Configuração criada com sucesso!");
          this.router.navigate(['/home']);

        }, err => {
          debugger
          this.util.closeLoading();
          this.util.messageError(err);
          //this.util.toasterShowError("Atenção", "Erro ao tentar salvar dados");
        });
    }
  }

  salvarEdicao() {
    if (this.validar()) {
      debugger
      let dados = this.pegarDados();
      dados.id = this.configQrcode.id;
      let url = API + "QrCode/" + this.idQrcode + "/Config";
      console.log(url);
      this.util.showLoading();
      this.ajax.put(url, dados)
        .subscribe((resp) => {
          debugger
          this.util.closeLoading();
          this.util.toasterShowSuccess("Sucesso", "Configuração alterada com sucesso!");
          this.router.navigate(['/home']);

        }, err => {
          debugger
          this.util.closeLoading();
          this.util.messageError(err);
          //this.util.toasterShowError("Atenção", "Erro ao tentar alterar dados");
        });
    }
  }

  voltar(){
    this.router.navigate(['/home']);
  }

  carregarForm() {
    debugger
    this.dataAtivacao = this.configQrcode.ativarEmData;
    this.dataExpiracao = this.configQrcode.dataExpiracao;
    this.senha = this.confirmacaoSenha = this.configQrcode.senha;
    this.qtdMaximoLeitura = this.configQrcode.quantidadeMaximaLeitura;
  }

  buscaQrcode() {
    this.util.showLoading();
    var url = API + 'QrCode/' + this.idQrcode + '/Config/';
    this.ajax.get(url)
      .subscribe((resp: ConfigQrcode) => {

        if (resp != null) {
          this.titulo = "Editar Configuração";
          this.configQrcode = resp;
          console.log(this.configQrcode);
          this.carregarForm();
        } else {
          this.titulo = "Cadastrar Configuração";
        }
        this.util.closeLoading();

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao buscar Dados");
      });
  }

}
