<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover">
  <thead>
    <tr>
      <th class="{{!cab.acao ? 'col-md-'+cab.tamanho : ''}}" *ngFor="let cab of arrayCabecalho">
        {{!cab.acao ? cab.label : ""}}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of arrayItens">
      <td class="col-md-{{cab.tamanho}} text-{{cab.align}}" *ngFor="let cab of arrayCabecalho">
        <span *ngIf="!cab['acao']"> {{item[cab['atributo']]}} </span>
        <span *ngIf="cab['acao']">
          <span *ngFor="let ac of cab['listAcao']">
            <button *ngIf="ac['label'] == 'SELECIONAR'" type="button" class="btn btn-icon btn-warning "
              ngbTooltip="Selecionar" (click)="tableSelecionar(item)">
              <i class="feather icon-plus-circle"></i>
            </button>&nbsp;
            <button *ngIf="ac['label'] == 'VISUALIZAR'" type="button" class="btn btn-icon btn-warning "
              ngbTooltip="Visualizar" (click)="tableVisualizar(item)">
              <i class="feather icon-eye"></i>
            </button>&nbsp;
            <button *ngIf="ac['label'] == 'EDITAR'" type="button" class="btn btn-icon btn-info " ngbTooltip="Editar"
              (click)="tableEditar(item)">
              <i class="fa fa-edit"></i>
            </button>&nbsp;
            <button *ngIf="ac['label'] == 'DELETAR'" type="button" class="btn btn-icon btn-danger "
              ngbTooltip="Deletar" (click)="tableDeletar(item)">
              <i class="fa fa-trash"></i>
            </button>
          </span>

        </span>
      </td>
    </tr>
  </tbody>
</table>
